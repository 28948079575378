.content {
  h1 {
    font-size: 3rem;
    font-weight: 400;
    padding: 16px 0 8px 0;
  }
  p {
    color: #333;
    line-height: 3rem;
    padding: 16px 0 8px 0;
  }
  li {
    text-indent: 20px;
    font-size: 1.6rem;
    color: #333;
    line-height: 3rem;
    font-family: "Nunito";
  }

  .puff {
    max-width: 1200px;
    margin: 120px auto 0 auto;
    display: grid;
    grid-template-areas: "text img";
    gap: 32px;

    .text {
      grid-area: text;
      max-width: 520px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      color: #4e4e4e;
      font-size: 1.6rem;
      line-height: 3rem;
      padding: 16px 0 8px 0;
      font-family: "Nunito";
    }

    img {
      grid-area: img;
      max-width: 500px;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .texttwo {
    margin-top: 120px;

    ul{
      text-indent: 20px;
      
    li {
      text-indent: 0;
    }
    }
  }

  .images {
    margin-top: 64px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    list-style: none;
    li {
      text-indent: 0;
      display: inline-block;
      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }

    .none {
      display: none;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .content {
    .puff {
      grid-template-areas:
        "img"
        "text";

      .text {
        margin: 0 auto;
      }
      img {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}
