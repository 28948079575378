$textColor: #fff;
$secondaryTextColor: #1d1d1d;

nav {
  background-color: transparent;
  z-index: 99;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 74px;

    max-width: 1880px;
    margin: 0 auto;
    padding: 0 20px;

    .logo {
      margin: 0;
      padding: 0;
      font-size: 2.2rem;
      font-weight: 400;
      color: $textColor;
      text-transform: uppercase;

      img {
        vertical-align: middle;
        max-height: 50px;
        margin-right: 16px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      select {
        /* margin: 0 auto 0 20px; */
        background-color: transparent;
        border: none;
        color: white;
        outline: none;

        option {
          color: black;
        }
      }
      select:active,
      select:hover {
        outline: none;
      }

      li {
        display: inline-block;
        color: $textColor;
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        padding: 14px;
        margin: 0 4px;
        border-radius: 4px;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          height: 2px;
          width: 0;
          margin: 0 auto;
          left: 0;
          right: 0;
          bottom: 7px;
          background-color: #fff;

          -o-transition: 0.5s;
          -ms-transition: 0.5s;
          -moz-transition: 0.5s;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }

        &:hover {
          &::after {
            width: 50%;
          }
        }
      }
    }

    ul,
    ul li {
      transition: all 0.5s;
    }

    .cta {
      color: #fff;
      border: 2px solid #ffffff;
      /* background-color: var(--accent); */
      background-color: transparent;
      font-size: 1.6rem;
      padding: 10px 16px;
      border-radius: 32px;
      cursor: pointer;

      &:hover {
        background-color: transparent;
      }
    }

    .mobileMenu {
      display: none;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      .bar {
        height: 4px;
        width: 36px;
        margin: 4px 0;
        border-radius: 2px;
        background-color: $textColor;

        &:first-child {
          width: 60%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  nav {
    background-color: rgb(80, 80, 80);
    position: fixed;
    .container {
      display: grid;
      grid-template-areas:
        "logo lang menu"
        "ul ul ul";
      grid-template-rows: 60px auto;
      min-height: 60px;

      .logo {
        grid-area: logo;
        font-size: 1.9rem;

        img {
          max-width: 40px;
        }
      }

      select {
        grid-area: lang;
        margin: 0 auto;
      }

      ul {
        grid-area: ul;
        display: none;
        li {
          display: block;
          text-align: center;
          padding: 20px;
          width: 100%;
          margin: 8px auto;
          font-weight: 600;
        }
      }

      .cta {
        border-radius: 4px;
        max-width: 450px;
      }

      .mobileMenu {
        grid-area: menu;
        display: block;
        float: right;
      }

      .active {
        display: block;
      }
    }
  }
}
