:root {
  --primary: #ffffff;
  --accent: #77ac1c;
  --textPrimary: #000;
  --textSecondary: #ada09b;
}

html,
body {
  background-color: #fff;
  font-family: "Poppins";
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
}

/* @media only screen and (max-width: 1100px) { 
  html {
    font-size: 55%;
  }
} */

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  max-width: 400px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  padding: 16px 0 8px 0;
  color: #4e4e4e;
}
h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.8rem;
}
h3 {
  font-size: 2.4rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.6rem;
}
h6 {
  font-size: 1.2rem;
}
p {
  font-size: 1.8rem;
  font-family: "Nunito";
}
li {
  font-size: 1.8rem;
  font-family: "Nunito";
}

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./assets/fonts/poppins-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/poppins-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./assets/fonts/poppins-v15-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/poppins-v15-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./assets/fonts/poppins-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/poppins-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./assets/fonts/poppins-v15-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/poppins-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-800 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("./assets/fonts/poppins-v15-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/poppins-v15-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./assets/fonts/nunito-v16-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/nunito-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./assets/fonts/nunito-v16-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/nunito-v16-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./assets/fonts/nunito-v16-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/nunito-v16-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-800 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("./assets/fonts/nunito-v16-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/nunito-v16-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
