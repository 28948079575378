.container {
  margin-top: 64px;

  .phone {
    margin: 32px 0;
  }

  .email {
    margin: 32px 0;
  }

  .address {
    margin: 32px 0;
    display: grid;
    grid-template-areas:
      "title title"
      ". ."
      "cent cent";
    grid-template-columns: 50% auto;
    column-gap: 20px;
    row-gap: 20px;

    .title {
      grid-area: title;
      margin: 0;
    }

    .location {
      iframe {
        margin-top: 24px;
        width: 100%;
        height: 300px;
      }
    }

    .cent {
      grid-area: cent;

      width: 50%;
      margin: 0 auto;
    }
  }

  .person {
    display: grid;
    grid-template-areas: "name number";
    grid-template-columns: 35% auto;
    margin-bottom: 16px;
  }

  .dummy {
    display: none;
  }

  .title {
    text-transform: uppercase;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
  }
  p {
    color: #333;
  }
  hr {
    border: 1px solid rgb(224, 224, 224);
  }

  a {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .person {
      display: grid;
      grid-template-areas: "name";
      grid-template-columns: unset;
      margin-bottom: 16px;

      p:first-child {
        font-weight: 600;
      }
    }

    .address {
      grid-template-areas:
        "title"
        ".";
      grid-template-columns: unset;

      .location {
        margin-top: 32px;
      }
      .cent {
        grid-area: unset;
        width: 100%;
      }
    }

    .title {
      margin-bottom: 0px;
    }
  }
}
