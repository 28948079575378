.container {
  margin-top: 300px;
  background-color: #383838;

  .content {
    .content {
      padding-top: 64px;
      display: grid;
      grid-template-areas:
        ". . ."
        ". . .";

      h1 {
        color: white;
        margin-bottom: 24px;
        position: relative;
        max-width: max-content;
        font-size: 2.2rem;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -6px;
          border-radius: 16px;
          height: 3px;
          width: 70%;
          background-color: #6a9918;
        }
      }
      p {
        color: #aaaaaa;
        font-size: 1.6rem;
        max-width: 340px;
        line-height: 24px;
      }

      ul {
        li {
          list-style: none;
          font-size: 1.6rem;
          color: #aaaaaa;
          line-height: 24px;
          margin-bottom: 12px;
        }

        .social {
          border-radius: 4px;
          background-color: #898F9C;
          width: 50px;
          height: 50px;
          margin-right: 16px;
          display: inline-block;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .content {
      .content {
        grid-template-areas:
          "."
          "."
          ".";
        gap: 32px;
      }
    }
  }
}
