.layout {
  .hero {
    height: 45vh;
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-size: cover;
    display: grid;
    grid-template-areas:
      "."
      "header";
    grid-template-rows: 74px auto;

    header {
      grid-area: header;
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        margin: 0;
        color: white;
        font-size: 6rem;
        font-weight: 500;
        line-break: auto;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .layout {
    .hero {
      grid-template-rows: 60px auto;
    }
  }
}
@media only screen and (max-width: 768px) {
  .layout {
    .hero {
      header {
        h1 {
          font-size: 4rem;
        }
      }
    }
  }
}
