.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);

  .modalContent {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    padding: 32px;
    border-radius: 8px;
    text-align: center;

    img {
      max-width: 234px;
    }

    h1 {
      color: #71be34;
      font-size: 32px;
      margin: 0;
    }

    .modal_button {
      border-radius: 20px;
      background-color: #71be34;

      border: 2px solid #71be34;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      padding: 10px 32px;
      margin: 40px 0 0 0;

      &:hover {
        background-color: #fff;
        color: #71be34;
        cursor: pointer;
      }
    }
  }
}
