.container {
  text-align: center;
  margin: 64px auto;

  .concepts {
    /*     display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 64px; */
    text-align: start;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .concept {
      flex-grow: 1;
      max-width: 400px;
      padding: 0 2rem;
      margin-top: 50px;

      h3 {
        margin: 8px 0 16px 0;
        padding: 0;
      }
      p {
        color: #4d4d4d;
        line-height: 2.6rem;
      }
      ul {
        margin-left: 20px;
        margin-top: 8px;
      }
      li {
        font-size: 1.4rem;
        color: #4d4d4d;
        margin-bottom: 8px;
      }
    }
  }
}
