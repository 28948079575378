.container {
  margin: 40px 0;
  text-align: center;

  h1 {
    font-size: 3.8rem;
    font-weight: normal;
  }

  .employees {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin: 64px 0;
    gap: 20px;
    row-gap: 64px;

    .employee {
      display: grid;
      margin: 0 auto;
      grid-template-areas:
        "img"
        "name"
        "position"
        "quote"
        "location";
      grid-template-rows: repeat(4, max-content);

      max-width: max-content;

      text-align: center;

      img {
        height: 300px;
        width: 300px;
        object-fit: contain;
        margin: 0 auto;
      }

      h1 {
        margin-top: 24px;
        font-weight: normal;
        font-size: 2.2rem;
        color: #2e2e2e;
      }

      p {
        color: #5c5c5c;
        font-size: 1.6rem;
      }

      .quote {
        padding-top: 16px;
        font-size: 1.8rem;
        line-height: 2.6rem;
        font-family: "Nunito";
      }

      @media only screen and (max-width: 1300px) {
        img {
          height: 200px;
          width: 200px;
        }
      }
    }
  }
}
