.container {
  max-width: 900px;
  margin: 64px auto 0 auto;
}

button {
  border: none;
  background-color: transparent;
  color: rgb(144, 144, 144);
  text-decoration: underline;
  margin-right: 20px;
  font-size: 1.8rem;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #333;
  }
}
.active {
  color: black;
}

form {
  max-width: 900px;
  display: inline-block;
  margin: 40px auto 0 auto;
  color: #333;

  a {
    text-decoration: underline;
    margin-left: 4px;
  }
  .error {
    color: red;
    font-size: 1.2rem;
    width: max-content;
  }
  label {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 16px 0 0;
    font-family: "Nunito";
    color: #1a1a1a;
  }
  input[type="text"] {
    height: 42px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid rgb(211, 211, 211);
    text-indent: 8px;
    background-color: #fafafa;
    margin: 4px 0 0 0;
    color: #333;
  }
  input[type="checkbox"],
  [type="radio"] {
    cursor: pointer;
    width: auto;
    height: auto;
    margin-right: 4px;
  }
  button {
    background-color: var(--accent);
    color: #fff;
    border: none;
    cursor: pointer;
    height: 40px;
    font-size: 1.8rem;
    width: 200px;
  }
}

.applicationForm {
  display: grid;
  grid-template-areas:
    ". ."
    "gender gender"
    "fname lname"
    "pnumber pnumber"
    "address address"
    "startdate startdate"
    "swedishresidency swedishresidency"
    "preschool preschool"
    "additionalsupport additionalsupport"
    "location location"
    "outdoorprogram outdoorprogram"
    "guardianinfo guardianinfo"
    "guardianone guardiantwo"
    "languagesspoken languagesspoken"
    "terms terms"
    "submit .";
  grid-template-columns: 48.2% auto;
  column-gap: 32px;
  row-gap: 20px;

  .gender {
    grid-area: gender;
  }
  .fname {
    grid-area: fname;
  }
  .lname {
    grid-area: lname;
  }
  .pnumber {
    grid-area: pnumber;
  }
  .address {
    grid-area: address;
  }
  .startdate {
    grid-area: startdate;
  }
  .swedishresidency {
    grid-area: swedishresidency;
  }
  .preschool {
    grid-area: preschool;
  }
  .additionalsupport {
    grid-area: additionalsupport;
  }
  .location {
    grid-area: location;
  }
  .outdoorprogram {
    grid-area: outdoorprogram;
  }
  .guardianinfo {
    grid-area: guardianinfo;
  }
  .guardian_one {
    grid-area: guardianone;
  }
  .guardian_two {
    grid-area: guardiantwo;
  }
  .languagesspoken {
    grid-area: languagesspoken;
  }
  .terms {
    grid-area: terms;
  }
  .submit {
    grid-area: submit;
  }
}

.reapplicationForm {
  display: grid;
  grid-template-areas:
    ". ."
    "fname lname"
    "pnumber pnumber"
    "approxdate approxdate"
    "newinfo newinfo"
    "terms terms"
    "submit .";
  grid-template-columns: 48.2% auto;
  column-gap: 32px;
  row-gap: 20px;

  .fname2 {
    grid-area: fname;
  }
  .lname2 {
    grid-area: lname;
  }
  .pnumber2 {
    grid-area: pnumber;
  }
  .approxdate {
    grid-area: approxdate;
  }
  .newinfo {
    grid-area: newinfo;
  }
  .terms2 {
    grid-area: terms;
  }
}

@media only screen and (max-width: 768px) {
  form {
    input[type="text"] {
      width: 100%;
    }
  }
  .applicationForm {
    grid-template-areas:
      "."
      "gender"
      "fname"
      "lname"
      "pnumber"
      "address"
      "startdate"
      "swedishresidency"
      "preschool"
      "additionalsupport"
      "location"
      "outdoorprogram"
      "guardianinfo"
      "guardianone"
      "guardiantwo"
      "languagesspoken"
      "terms"
      "submit";
    grid-template-columns: unset;
  }
  .reapplicationForm {
    grid-template-areas:
      "."
      "fname"
      "lname"
      "pnumber"
      "approxdate"
      "newinfo"
      "terms"
      "submit";
    grid-template-columns: unset;
  }
}
